<template>
  <el-container>
    <el-row style="width: 100%">
      <el-col :span="24">
        <el-form ref="form" :model="form" label-width="200px" label-position="right" size="mini">
          <el-form-item label="姓名:">
              <input type="text" v-model="form.name" disabled>
            </el-form-item>
            <el-form-item label="性别:">
                <input type="text" v-model="form.gender" disabled>
            </el-form-item>
            <el-form-item label="身高:">
                <input type="text" v-model="form.height" disabled>
            </el-form-item>
            <el-form-item label="体重:">
                <input type="text" v-model="form.weight" disabled>
            </el-form-item>
            <el-form-item label="偏瘫侧:">
                <input type="text" v-model="form.hemiplegicSide" disabled>
            </el-form-item>
            <el-form-item label="状态:">
                <input type="text" v-model="form.statusStr" disabled>
            </el-form-item>
            <el-form-item label="住院号:">
                <input type="text" v-model="form.outpatientNumber" disabled>
            </el-form-item>
            <el-form-item label="出生年月:">
                <input type="text" v-model="form.birthDay" disabled>
            </el-form-item>
            <el-form-item label="联系方式:">
                <input type="text" v-model="form.phone" disabled>
            </el-form-item>
            <el-form-item label="家属电话:">
                <input type="text" v-model="form.emergencyPhone" disabled>
            </el-form-item>
            <el-form-item label="责任护士姓名:">
                <input type="text" v-model="form.nurse.name" disabled>
            </el-form-item>
            <el-form-item label="责任护士编号:">
                <input type="text" v-model="form.nurse.jobNumber" disabled>
            </el-form-item>
          <!-- <el-form-item style="float:right">
            <el-button type="danger" @click="onDelete" v-if="form.guid !== ''">删除</el-button>
            <el-button type="primary" @click="onSubmit">保存</el-button>
          </el-form-item> -->
        </el-form>
      </el-col>
    </el-row>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
        userInfo: {},
        form: { 
            birthDay: '',
            createTime: '',
            emergencyPhone: '',
            gender: '',
            guid: '',
            height: '',
            hemiplegicSide: '',
            isRegister: '',
            isSubmit: '',
            medicineNotifyStatus: '',
            name: '',
            nurse: {},
            nurseGuid: '',
            openid: '',
            outpatientNumber: '',
            phone: '',
            statusStr: '',
            token: '',
            updateTime: '',
            weight: ''
        }
    }
  },
  created() {
    let self = this;
    self.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    self.getUserDetail(self.$route.query.guid || null)
  },
  methods: {
    // 获取护士详情
    getUserDetail(guid) {
      let self = this;
      if (guid) {
        self.$axios
          .get("/api/member/get/" + guid, {
            headers: { token: self.userInfo.token },
          })
          .then(function(response) {
            self.form.guid = response.data.data.guid;
            self.form.name = response.data.data.name;
            self.form.gender = response.data.data.gender;
            self.form.height = response.data.data.height;
            self.form.weight = response.data.data.weight;
            self.form.hemiplegicSide = response.data.data.hemiplegicSide;
            self.form.statusStr = response.data.data.statusStr;
            self.form.outpatientNumber = response.data.data.outpatientNumber;
            self.form.birthDay = response.data.data.birthDay;
            self.form.phone = response.data.data.phone; 
            self.form.emergencyPhone = response.data.data.emergencyPhone; 
            self.form.phone = response.data.data.phone; 
            self.form.nurse.name = response.data.data.nurse.name; 
            self.form.nurse.jobNumber = response.data.data.nurse.jobNumber; 
          })
          .catch(function(error) {
            self.$message({
              message: error.response.data.message,
              type: "warning",
            });
          });
      }
    },
    // // 保存
    // onSubmit() {
    //   let self = this;
    //   self.$refs["form"].validate((valid) => {
    //     if (valid) {
    //       let data = JSON.parse(JSON.stringify(self.form));
    //       self.$axios
    //         .post("/api/nurse/saveOrUpdate", data, {
    //           headers: {
    //             "content-type": "application/json",
    //             token: self.userInfo.token,
    //           },
    //         })
    //         .then(function() {
    //           self.$message({
    //             message: "操作成功",
    //             type: "success",
    //           });
    //         //   self.$router.push({ name: "nurse_list" });
    //         })
    //         .catch(function(error) {
    //           self.$message({
    //             message: error.response.data.message,
    //             type: "warning",
    //           });
    //         });
    //     } else {
    //       self.$message({
    //         message: "请输入正确的管理员信息",
    //         type: "warning",
    //       });
    //       return false;
    //     }
    //   });
    // },
    // // 删除
    // onDelete() {
    //   let self = this;
    //   self
    //     // .$confirm("确定删除 " + (self.form.name || "未命名用户") + "？")
    //     .then(() => {
    //       self.$axios
    //         .delete("/api/nurse/delete", {
    //           data: { guid: self.form.guid },
    //           headers: { token: self.userInfo.token },
    //         })
    //         .then(() => {
    //           self.$message({
    //             message: "删除成功",
    //             type: "success",
    //           });
    //         //   self.$router.push({ name: "nurse_list" });
    //         })
    //         .catch((error) => {
    //           self.$message({
    //             message: error.response.data.message,
    //             type: "warning",
    //           });
    //         });
    //     })
    //     .catch(() => {});
    // }
  }
};
</script>

<style>
  .el-transfer-panel{
  width: 30%;
}
</style>
